import { formatBTC, formatDate, formatTimeSince } from '@/lib/formatters';
import { KitDateBidSummary } from '@/schemas/auction';
import { useAccountStore } from '@/stores/accountStore';
import { useEffect, useRef, useState } from 'react';
import { MemoizedUserBubble } from './Bubble';
import { GradientOverlays } from './GradientOverlays';
import { KitBlankImg } from './KitBlank';

type AuctionCardProps = { bidDate: KitDateBidSummary; onClick?: () => void; };

export const AuctionCard = ({ bidDate, onClick }: AuctionCardProps) => {
    const { top_bid: topBid, total_bids: totalBids } = bidDate;
    const userOrdinalAddress = useAccountStore(state => state.ordinalAddress);
    const [isHovered, setIsHovered] = useState(false);
    const hoverRef = useRef(false);
    const cardRef = useRef<HTMLDivElement>(null);
    const isUserBid = topBid?.ordinal_address === userOrdinalAddress;
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    const [monthDay, year] = formatDate(topBid.kit_date).split(', ');

    useEffect(() => {
        if (isHovered) { hoverRef.current = true }
        return () => { hoverRef.current = false };
    }, [isHovered]);

    return (
        <div
            ref={cardRef}
            className={`
                relative 
                aspect-[0.95]
                w-full
                min-[640px]:w-full 
                grid grid-cols-12 grid-rows-12
                rounded-lg px-[5%] py-[2%]
                overflow-hidden
                border border-muted-foreground 
                ${topBid.rank[0] > 16 ? 'border-muted-foreground/20 filter blur-[0.5px] opacity-25' : 'border-opacity-100'}
                ${isUserBid ? 'shadow-lg outline-double outline-offset-1 outline-muted-foreground/80  shadow-indigo-200/30 hover:shadow-indigo-200/20' : ' shadow-zinc-900/20 hover:shadow-zinc-900/10'}
                hover:cursor-pointer hover:translate-y-[1px] 
            `}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
        >
            <GradientOverlays />

            <div
                className='
                row-start-1 col-start-1 z-[50]
                text-xl sm:text-xs md:text-xs lg:text-xs xl:text-sm text-foreground 
            '>
                {topBid.rank[0]}
            </div>

            <div className={
                `
                z-0 h-full w-full aspect-square
                col-start-1 row-start-1 col-span-full row-span-full
            `}>
                <KitBlankImg date={topBid.kit_date} rank={topBid.rank[0]} />
            </div>

            <div className={`
                col-start-1 row-start-1 col-span-12
                text-right
                z-[51] 
                opacity-100
                text-xl sm:text-sm lg:text-sm xl:text-base
                kit-date-semibold
                text-primary transition-colors
                `}>
                <div>{monthDay},</div>
                <div>{year}</div>
                <span className='
                    text-sm sm:text-xs lg:text-sm
                    opacity-90 z-[51]
                    hover:opacity-100 hover:scale-[101%] hover:underline
                '>
                    {totalBids} Bid{totalBids > 1 ? 's' : ''}
                </span>
            </div>

            <div className="
                z-[51]
                mt-[0.4rem] sm:mt-[0.7rem] md:mt-2 lg:mt-2 xl:mt-3
                relative mx-auto text-nowrap row-start-9 sm:row-start-8 col-start-1 text-center col-span-full  kit-date-regular
                ">
                <div className='
                text-secondary-foreground text-sm sm:text-xs md:text-xs lg:text-xs xl:text-sm
                '>
                    {formatBTC(topBid?.amount)} BTC
                </div>
                <div className="-mt-[0.2rem]
                text-xs sm:text-[10px] md:text-[8px] lg:text-[8px] xl:text-[9px]
                text-muted-foreground kit-date-medium">
                    {formatTimeSince(topBid?.created)}
                </div>
            </div>

            <div className='absolute bottom-0 left-0 w-full z-[50]'>
                <MemoizedUserBubble className='
                    h-[0.5rem] sm:h-[0.6rem] md:h-2.5 lg:h-3 xl:h-4
                    pt-3 pb-1 sm:pb-0
                    text-[9px] sm:text-[7px] md:text-[7px] lg:text-[7.5px] xl:text-[8.5px]
                    '
                    address={topBid.ordinal_address} isUserBid={isUserBid} />
            </div>
        </div >
    );
};
