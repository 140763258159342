import { getSunSignWithCusps } from '@/lib/getSunSignWithCusps';

export interface AuctionSundialProps {
    date: string;
    totalBids?: number;
    isHovered: boolean;
    dateColor?: string;
    imgClassName?: string;
}

export const KitBlankImg = ({ date, rank }: { date: string, rank: number }) => {
    const isGray = rank > 16;
    const sunSignResult = getSunSignWithCusps(date);
    const getImageSrc = (index: string, sign: string) =>
        `https://assets.ephemera.gallery/kit_blank/${index}_${sign.toLowerCase()}.png`;
    
    const imgClass = `
        absolute left-1/2 top-[46%] transform -translate-x-1/2 -translate-y-1/2 
        w-[90%] h-fit aspect-square ${isGray ? 'grayscale' : ''}
        transition-all duration-75 ease-in`;

    if (Array.isArray(sunSignResult)) {
        const [sign1, sign2] = sunSignResult;
        const maskImageVal = `linear-gradient(60deg, transparent 25%, black 65%)`;
        return (
            <>
                <div
                    title={`Blended kit for ${sign1.sign}/${sign2.sign} cusp`}
                    className={imgClass}
                    style={{
                        backgroundImage: `url(${getImageSrc(sign1.index, sign1.sign)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <div
                    className={imgClass}
                    style={{
                        backgroundImage: `url(${getImageSrc(sign2.index, sign2.sign)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        maskImage: maskImageVal,
                        WebkitMaskImage: maskImageVal,
                    }}
                />
            </>
        );
    } else {
        // Single sign
        return (
            <div
                className={imgClass}
                style={{
                    backgroundImage: `url(${getImageSrc(sunSignResult.index, sunSignResult.sign)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                title={`Kit for ${sunSignResult.sign}`}
            />
        );
    }
}